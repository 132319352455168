/**
 * 共通のカスタムフック
 */
export const useCommon = () => {
    /**
     * セクションタイトルを分割する
     *
     * @param sectionTitle セクションタイトル
     * @returns セクションタイトルの初めの文字とそれ以外の文字
     */
    const splistSectionTitle = (sectionTitle: string) => {
        const initial = sectionTitle.slice(0, 1)
        const rest = sectionTitle.slice(1)

        return {
            initial,
            rest,
        }
    }

    return {
        splistSectionTitle,
    }
}
