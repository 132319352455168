import styled from 'styled-components'
import Logo from './HeaderLogoComponent'
import GlobalNav from './GlobalNavComponent'
import { useHeader } from '../../hooks/headerHooks'

/**
 * ページヘッダ
 */
const HeaderComponent = () => {
    const Header = styled.header`
        position: fixed;
        top: 0;
        z-index: 100;
        width: 100%;
        height: 60px;
        background-color: #eff5f2;
    `

    const HeaderInner = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding-left: 10px;
    `

    const NavBtn = styled.button`
        position: relative;
        z-index: 999;
        width: 60px;
        height: 60px;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        // PCの場合は非表示
        @media screen and (min-width: 1024px) {
            display: none;
        }
    `

    const NavBtnLine = styled.span`
        position: absolute;
        translate: -50%;
        left: 50%;
        display: block;
        width: 45%;
        height: 1px;
        background-color: #171d1b;
        transition: all 0.4s;

        &:nth-child(1) {
            top: 30%;
        }
        &:nth-child(2) {
            top: 50%;
            opacity: 1;
        }
        &:nth-child(3) {
            top: 70%;
        }

        // Active時にナビゲーションボタンをクロスさせる
        &.active {
            &:nth-child(1) {
                top: 50%;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                top: 50%;
                transform: rotate(-45deg);
            }
        }
    `

    const headerHooks = useHeader()

    return (
        <Header>
            <HeaderInner>
                <Logo />
                <GlobalNav />
                <NavBtn onClick={headerHooks.toggleClass}>
                    <NavBtnLine className="nav-btn-line"></NavBtnLine>
                    <NavBtnLine className="nav-btn-line"></NavBtnLine>
                    <NavBtnLine className="nav-btn-line"></NavBtnLine>
                </NavBtn>
            </HeaderInner>
        </Header>
    )
}

export default HeaderComponent
