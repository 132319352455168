import styled from 'styled-components'
import { useCompany } from '../../hooks/companyHooks'

/**
 * Google Mapを表示するコンポーネント
 * tsxにするとiframeの属性がエラーになるため、jsxで記述
 */
const MapComponent = () => {
    const MapWrapper = styled.div`
        width: 100%;
        aspect-ratio: 2 / 1;
        margin-bottom: 60px;

        @media screen and (min-width: 768px) {
            box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.5);
            border-radius: 20px;
        }
    `

    const Map = styled.iframe`
        width: 100%;
        height: 100%;
        
        @media screen and (min-width: 768px) {
            border-radius: 20px;
        }
    `

    const companyHooks = useCompany()

    return (
        <MapWrapper ref={companyHooks.mapRef}>
            <Map
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d201.52960583614762!2d140.11043691896134!3d36.081939291980824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f80.0!3m3!1m2!1s0x60220c7d30bfffff%3A0xf6cc73004c20afb4!2z44CSMzA1LTAwMzEg6Iyo5Z-O55yM44Gk44GP44Gw5biC5ZC-5aa777yR5LiB55uuNeKIku-8lyDjg4DjgqTjg6_jg63jgqTjg43jg4Pjg4jjg5vjg4bjg6vjgaTjgY_jgbAg77yS6ZqO!5e0!3m2!1sja!2sjp!4v1722673579794!5m2!1sja!2sjp"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></Map>
        </MapWrapper>
    )
}

export default MapComponent
