import { useCommon } from '../../hooks/commonHooks'
import { useCompany } from '../../hooks/companyHooks'
import Map from './MapComponent'
import CompanyInfo from './CompanyInfoComponent'
import styled from 'styled-components'

/**
 * Companyセクションのコンポーネント
 */
const CompanyComponent = () => {
    const Company = styled.div`
        width: 100%;
        background-color: #fff;
        padding: 40px 0;

        @media screen and (min-width: 768px) {
            padding: 80px 10%;
        }
    `

    const CompanySectionHeading = styled.h2`
        font-family: a-otf-ryumin-pr6n, serif;
        font-size: 40px;
        font-weight: 400;
        letter-spacing: 0.5rem;
        text-align: center;
        margin-bottom: 40px;

        @media screen and (min-width: 1024px) {
            font-size: 60px;
        }
    `

    const SectionTitleInitial = styled.span`
        color: #4bad9c;
    `

    const commonHooks = useCommon()
    const companyHooks = useCompany()
    const splitedTitle = commonHooks.splistSectionTitle('Company')

    return (
        <Company id="company">
            {/* Section Title */}
            <CompanySectionHeading ref={companyHooks.companyHeadingRef}>
                <SectionTitleInitial>{splitedTitle.initial}</SectionTitleInitial>
                {splitedTitle.rest}
            </CompanySectionHeading>

            {/* GoogleMap */}
            <Map />

            {/* CompanyInfo */}
            <CompanyInfo />
        </Company>
    )
}

export default CompanyComponent
