/**
 * Headerのカスタムフック
 */
export const useHeader = () => {
    /**
     * ナビゲーションボタンのクラス
     */
    const toggleClass = () => {
        const navBtnLine = document.querySelectorAll('.nav-btn-line')
        const globalNav = document.querySelector('.global-nav')
        navBtnLine.forEach((line) => {
            line.classList.toggle('active')
        })
        globalNav?.classList.toggle('active')
    }

    return { toggleClass }
}
