import styled from 'styled-components'
import Header from '../components/header/HeaderComponent'
import Top from '../components/top/TopComponent'
import Service from '../components/service/ServiceComponent'
import Company from '../components/company/CompanyComponent'
import Footer from '../components/footer/FooterComponent'

/**
 * このファイルから作成する
 */
export const MainPage = () => {
    const Main = styled.div`
        position: relative;
        min-height: 100vh;
        background-color: #eff5f2;
        overflow-x: hidden;
    `

    return (
        <Main id="#top">
            <Header />
            <Top />
            <Service />
            <Company />
            <Footer />
        </Main>
    )
}
