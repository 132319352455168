import React from 'react'
import logo from './logo.svg'
import { MainPage } from './pages/main'

function App() {
    return (
        <div className="App">
            <MainPage />
        </div>
    )
}

export default App
