import styled from 'styled-components'

interface ServiceImageComponentProps {
    imgSrc: string
    alt: string
}

/**
 * Serviceセクションの画像部分のコンポーネント
 */
const ServiceImageComponent: React.FC<ServiceImageComponentProps> = (props) => {
    const ItemWrapper = styled.div`
        width: 100%;
        height: 100%;

        @media screen and (min-width: 1024px) {
            width: 40%;
            height: 100%;
            aspect-ratio: inherit;
        }
    `

    const ServiceImg = styled.img`
        display: block;
        width: 100%;
        aspect-ratio: 4 / 3;
        object-fit: cover;

        @media screen and (min-width: 768px) {
            aspect-ratio: 16 / 9;
        }

        @media screen and (min-width: 1024px) {
            height: 100%;
            aspect-ratio: inherit;
            border-radius: 20px;
            box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.5);
        }
    `

    return (
        <ItemWrapper>
            <ServiceImg src={props.imgSrc} alt={props.alt} />
        </ItemWrapper>
    )
}

export default ServiceImageComponent
