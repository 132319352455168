import { useTop } from '../../hooks/topHooks'
import mv from '../../assets/office.jpg'
import mvsp from '../../assets/office-sp.jpg'
import logo from '../../assets/logo-green.jpg'
import styled from 'styled-components'

/**
 * TOPコンポーネント
 */
const TopComponent = () => {
    const Top = styled.div`
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 60px;
        margin-bottom: 60px;
        height: calc(100vh - 60px);

        @media screen and (min-width: 768px) {
            padding: 30px 0;
            margin-bottom: 0;
        }
    `

    const TopMVWrapper = styled.div`
        position: relative;
        width: 100%;
        height: 100%;

        @media screen and (min-width: 768px) {
            width: 70%;
        }
    `

    const TopMV = styled.picture`
        width: 100%;
        height: 100%;

        img,
        source {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media screen and (min-width: 768px) {
                border-radius: 20px 0 0 20px;
                box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.5);
            }
        }
    `

    const Logo = styled.img`
        position: absolute;
        transform: translate(-50%, -50%);
        top: 25%;
        left: 50%;
        width: 200px;
        height: 200px;
        box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.5);

        @media screen and (min-width: 768px) {
            transform: translate(-125px, -50%);
            top: 35%;
            left: 0;
        }
        @media screen and (min-width: 1024px) {
            width: 250px;
            height: 250px;
        }
    `

    const CatchCopyWrapper = styled.div`
        position: absolute;
        top: 50%;
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        background-color: rgba(255, 255, 255, 0.85);

        @media screen and (min-width: 768px) {
            top: 60%;
            left: -125px;
            padding-left: 40px;

            background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0.9) 0%,
                rgba(255, 255, 255, 0.9) 90%,
                rgba(255, 255, 255, 0) 100%
            );
        }

        @media screen and (min-width: 1024px) {
            width: 800px;
        }
    `

    const CatchCopy = styled.p`
        width: 100%;
        font-family: a-otf-ryumin-pr6n, serif;
        font-size: 20px;
        font-weight: 300;
        text-align: center;
        letter-spacing: 0.5rem;
        color: #4bad9c;

        @media screen and (min-width: 768px) {
            width: auto;
            text-align: left;
            font-size: 30px;
            letter-spacing: 1.15rem;
        }

        @media screen and (min-width: 1024px) {
            font-size: 50px;
        }
    `

    const topHooks = useTop()

    return (
        <Top>
            <TopMVWrapper>
                <TopMV>
                    <source media="(max-width: 767px)" srcSet={mvsp} />
                    <source media="(min-width: 768px)" srcSet={mv} />
                    <img src={mv} alt="オフィス" />
                </TopMV>
                <Logo src={logo} alt="株式会社STK" ref={topHooks.logRef} />
                <CatchCopyWrapper ref={topHooks.catchCopyRef}>
                    <CatchCopy>未来を創造する会社</CatchCopy>
                </CatchCopyWrapper>
            </TopMVWrapper>
        </Top>
    )
}

export default TopComponent
