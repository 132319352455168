import { useRef } from 'react'
import { gsapUtil } from '../common/gsapUtil'
import { useGSAP } from '@gsap/react'

import batteryImg from '../assets/battery.jpg'
import ecoCuteImg from '../assets/eco-cute.jpg'
import solarImg from '../assets/solar-panel.jpg'
import clearWaterImg from '../assets/clear-water.jpg'
import renovationImg from '../assets/renovation.jpg'
import supportImg from '../assets/support.jpg'
import beautyImg from '../assets/beauty.jpg'
import itImg from '../assets/it.jpg'
import mobileImg from '../assets/smartphone.jpg'
import batteryIcon from '../assets/icon/battery_charging_full_300dp_FFFFFF_FILL0_wght200_GRAD0_opsz48.svg'
import ecocuteIcon from '../assets/icon/gas_meter_250dp_EFF5F2_FILL0_wght200_GRAD0_opsz48.svg'
import solarIcon from '../assets/icon/solar_power_250dp_FFFFFF_FILL0_wght200_GRAD0_opsz48.svg'
import waterIcon from '../assets/icon/water_drop_250dp_EFF5F2_FILL0_wght200_GRAD0_opsz48.svg'
import homeIcon from '../assets/icon/cottage_250dp_FFFFFF_FILL0_wght200_GRAD0_opsz48.svg'
import supportIcon from '../assets/icon/group_250dp_EFF5F2_FILL0_wght200_GRAD0_opsz48.svg'
import beautyIcon from '../assets/icon/health_and_beauty_250dp_FFFFFF_FILL0_wght200_GRAD0_opsz48.svg'
import itIcon from '../assets/icon/computer_250dp_EFF5F2_FILL0_wght200_GRAD0_opsz48.svg'
import mobileIcon from '../assets/icon/phone_iphone_250dp_FFFFFF_FILL0_wght200_GRAD0_opsz48.svg'

/**
 * Serviceセクション用カスタムフック
 */
export const useService = () => {
    const gsap = gsapUtil().gsap
    const serviceHeadingRef = useRef(null)
    const batteryRef = useRef(null)
    const ecoCuteRef = useRef(null)
    const solarRef = useRef(null)
    const waterRef = useRef(null)
    const renovationRef = useRef(null)
    const supportRef = useRef(null)
    const beautyRef = useRef(null)
    const itRef = useRef(null)
    const mobileRef = useRef(null)

    /**
     * Serviceセクションのデータ
     */
    const serviceArray = [
        {
            sectionTitle: 'Service',
            serviceTitle: '蓄電池',
            serviceDescription: '環境に優しいエネルギー管理を可能にし、日常生活の中での電力使用を最適化します。',
            serviceImg: batteryImg,
            bgIconSrc: batteryIcon,
            iconRotate: 30,
            serviceRef: batteryRef,
        },
        {
            sectionTitle: undefined,
            serviceTitle: 'エコキュート',
            serviceDescription:
                'エコキュートの販売・設置を行っています。高効率な給湯システムで、環境に優しく光熱費を削減し、快適な生活をサポートします。',
            serviceImg: ecoCuteImg,
            bgIconSrc: ecocuteIcon,
            iconRotate: undefined,
            serviceRef: ecoCuteRef,
        },
        {
            sectionTitle: undefined,
            serviceTitle: 'ソーラーパネル',
            serviceDescription:
                '高性能なソーラーパネルで自家発電を実現し、電気代の節約と環境保護に貢献します。導入からメンテナンスまで、専門スタッフが丁寧にサポートし、安心してご利用いただけるサービスを提供します。',
            serviceImg: solarImg,
            bgIconSrc: solarIcon,
            serviceRef: solarRef,
        },
        {
            sectionTitle: undefined,
            serviceTitle: '浄水器',
            serviceDescription:
                '高品質な家庭用浄水器の販売・設置を行なっております。安心・安全な飲料水を提供し、健康的な生活をサポートします。清潔でおいしい水を、いつでも手軽にお楽しみいただけます。',
            serviceImg: clearWaterImg,
            bgIconSrc: waterIcon,
            serviceRef: waterRef,
        },
        {
            serviceTitle: '建築リフォーム',
            serviceDescription:
                'お客様の住まいをより快適で機能的な空間に改装します。キッチンやバスルームのリフォームから全体的なリノベーションまで、幅広いニーズに対応します。',
            serviceImg: renovationImg,
            bgIconSrc: homeIcon,
            serviceRef: renovationRef,
        },
        {
            serviceTitle: '就労支援',
            serviceDescription:
                '就労支援事業を通じて、求職者の就業機会を広げるサポートを行なっています。キャリアカウンセリングやスキルアップ研修など個々のニーズに応じたきめ細やかな支援を実施。働く喜びを感じるためのサポートを全力で行います。',
            serviceImg: supportImg,
            bgIconSrc: supportIcon,
            serviceRef: supportRef,
        },
        {
            serviceTitle: '美容事業',
            serviceDescription:
                '美容系サロンを経営されている方、独立してサロン経営をなさる方でもSNSを通じてスムーズにお取引をさせて頂きます。',
            serviceImg: beautyImg,
            bgIconSrc: beautyIcon,
            serviceRef: beautyRef,
        },
        {
            serviceTitle: 'IT事業',
            serviceDescription:
                '光回線、営業代行等さまざまな事業を行わせていただいております。\n取扱商品：ハルエネ電気、ニチガス、情報通信業（NTT、SoftBank、KDDI）',
            serviceImg: itImg,
            bgIconSrc: itIcon,
            serviceRef: itRef,
        },
        {
            serviceTitle: 'モバイル事業',
            serviceDescription:
                'モバイル事業を通して最新のスマートフォンやモバイルサービスを提供しています。お客様のニーズに合わせたプランとサポートで、快適なモバイルライフを充実します。\n取扱商品：SoftBank',
            serviceImg: mobileImg,
            bgIconSrc: mobileIcon,
            serviceRef: mobileRef,
        },
    ]

    useGSAP(() => {
        gsap.from(serviceHeadingRef.current, {
            opacity: 0,
            duration: 1.5,
            delay: 0.25,
            scrollTrigger: {
                trigger: serviceHeadingRef.current,
                start: 'top 80%',
            },
        })
        serviceArray.forEach((service, index) => {
            if (index % 2 === 0) {
                gsap.from(service.serviceRef.current, {
                    opacity: 0,
                    duration: 1.5,
                    delay: 0.25,
                    x: -100,
                    scrollTrigger: {
                        trigger: service.serviceRef.current,
                        start: 'top 80%',
                    },
                })
            } else {
                gsap.from(service.serviceRef.current, {
                    opacity: 0,
                    duration: 1.5,
                    delay: 0.25,
                    x: 100,
                    scrollTrigger: {
                        trigger: service.serviceRef.current,
                        start: 'top 80%',
                    },
                })
            }
        })
    })

    return { serviceArray, serviceHeadingRef }
}
