import { useRef } from 'react'
import { gsapUtil } from '../common/gsapUtil'
import { useGSAP } from '@gsap/react'

/**
 * Topセクションのカスタムフック
 */
export const useTop = () => {
    const gsap = gsapUtil().gsap
    const logRef = useRef(null)
    const catchCopyRef = useRef(null)

    useGSAP(() => {
        gsap.from(logRef.current, {
            opacity: 0,
            duration: 1.5,
            delay: 0.5,
        })
        gsap.from(catchCopyRef.current, {
            opacity: 0,
            duration: 1.5,
            delay: 1,
        })
    })

    return { logRef, catchCopyRef }
}
