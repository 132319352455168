import styled from 'styled-components'
import { useHeader } from '../../hooks/headerHooks'

/**
 * グローバルナビゲーションコンポーネント
 */
const GlobalNavComponent = () => {
    const Nav = styled.nav`
        position: absolute;
        top: 0;
        right: -100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: #eff5f2;
        transition: all 0.4s;

        &.active {
            right: 0;
        }

        @media screen and (min-width: 1024px) {
            right: 0;
            flex-direction: row;
            gap: 10px;
            width: auto;
            height: 100%;
        }
    `

    const NavLink = styled.a`
        display: block;
        width: 90%;
        margin: 0 auto;
        padding: 12.5px 25px;
        border-bottom: 0.5px solid #171d1b;
        font-size: 20px;
        font-weight: 400;
        transition: all 0.4s;
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            background-color: rgba(255, 255, 255, 0.7);
        }

        @media screen and (min-width: 1024px) {
            display: flex;
            justify-content: center;
            align-items: center;
            width: auto;
            height: 100%;
            padding: 0 25px;
            margin: 0;
            border-bottom: none;
        }
    `

    const headerHooks = useHeader()

    return (
        <Nav className="global-nav">
            <NavLink href="#top" onClick={headerHooks.toggleClass}>
                Top
            </NavLink>
            <NavLink href="#service" onClick={headerHooks.toggleClass}>
                Service
            </NavLink>
            <NavLink href="#company" onClick={headerHooks.toggleClass}>
                Company
            </NavLink>
        </Nav>
    )
}

export default GlobalNavComponent
