import styled from 'styled-components'

const FooterComponent = () => {
    const Footer = styled.footer`
        width: 100%;
        background-color: #fff;
    `

    const FooterInner = styled.div`
        width: 90%;
        padding: 40px 0 20px;
        margin: 0 auto;
        background-color: #171d1b;
        border-radius: 20px 20px 0 0;
    `

    const Sitemap = styled.div`
        display: flex;
        justify-content: center;
        gap: 30px;
        margin-bottom: 40px;
    `

    const SitemapLink = styled.a`
        font-size: 20px;
        color: #fff;
    `

    const CopyRight = styled.p`
        font-size: 14px;
        color: #fff;
        text-align: center;
    `

    return (
        <Footer>
            <FooterInner>
                <Sitemap>
                    <SitemapLink href="#top">Top</SitemapLink>
                    <SitemapLink href="#service">Service</SitemapLink>
                    <SitemapLink href="#company">Company</SitemapLink>
                </Sitemap>
                <CopyRight>&copy; 2024 STK Co.,Ltd. All Rights Reserved.</CopyRight>
            </FooterInner>
        </Footer>
    )
}

export default FooterComponent
