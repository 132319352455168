import wave from '../../assets/effect/wave.svg'
import waveGreen from '../../assets/effect/wave-green.svg'
import styled from 'styled-components'

interface ServiceWrapperComponentProps {
    index: number
    arrayLastIndex: number
    children: React.ReactNode
}

/**
 * Serviceごとのコンテンツをラップするコンポーネント
 */
const ServiceWrapperComponent: React.FC<ServiceWrapperComponentProps> = (props) => {
    const ServiceWrapper = styled.div`
        position: relative;
        width: 100%;
        background-color: ${(props.index + 1) % 2 === 0 ? '#fff' : '#eff5f2'};

        // 波線のスタイル
        &::after {
            display: ${props.index + 1 === props.arrayLastIndex ? 'block' : 'none'};
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20px;
            background-image: url(${(props.index + 1) % 2 === 0 ? waveGreen : wave});
            background-size: cover;
        }

        @media screen and (min-width: 1024px) {
            height: 100vh;
            padding: 80px 10%;

            // 波線のスタイル
            &::after {
                display: block;
            }
        }
    `

    return <ServiceWrapper>{props.children}</ServiceWrapper>
}

export default ServiceWrapperComponent
