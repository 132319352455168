import { useCommon } from '../../hooks/commonHooks'
import styled from 'styled-components'

interface ServiceContentComponentProps {
    sectionTitle?: string
    serviceTitle: string
    serviceDescription: string
    bgIconSrc: string
    iconRotate?: number
}

/**
 * Serviceセクションの文章コンテンツ部分のコンポーネント
 */
const ServiceContentComponent: React.FC<ServiceContentComponentProps> = ({
    sectionTitle,
    serviceTitle,
    serviceDescription,
    bgIconSrc,
    iconRotate = 0,
}) => {
    const ItemWrapper = styled.div`
        position: relative;
        width: 100%;
        min-height: 300px;

        &::before {
            content: '';
            position: absolute;
            transform: translateX(-50%);
            top: 50px;
            left: 50%;
            z-index: 1;
            display: block;
            width: 200px;
            height: 200px;
            background-image: url(${bgIconSrc});
            background-size: contain;
            rotate: ${iconRotate}deg;
        }

        @media screen and (min-width: 1024px) {
            width: 60%;
            height: 100%;

            &::before {
                top: inherit;
                bottom: 0;
                width: 400px;
                height: 400px;
            }
        }
    `

    const ServiceContentInner = styled.div`
        position: relative;
        z-index: 3;
        width: 80%;
        margin: 0 auto;

        @media screen and (min-width: 1024px) {
            width: 100%;
            height: 100%;
            padding-top: 100px;
        }
    `

    const ServiceSectionHeading = styled.h2`
        display: none;
        font-family: a-otf-ryumin-pr6n, serif;
        font-size: 60px;
        font-weight: 400;
        letter-spacing: 0.5rem;
        margin-bottom: 40px;

        @media screen and (min-width: 1024px) {
            display: block;
        }
    `

    const SectionHeadingInitial = styled.span`
        color: #4bad9c;
    `

    const ServiceItemHeading = styled.h3`
        display: inline-block;
        font-family: a-otf-ryumin-pr6n, serif;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 0.5rem;
        border-bottom: 1px solid #4bad9c;
        margin-bottom: 30px;

        @media screen and (min-width: 1024px) {
            font-size: 40px;
            margin-bottom: 50px;
            padding-bottom: 5px;
            border-bottom: 3px solid #4bad9c;
        }
    `

    const ServiceDescription = styled.p`
        white-space: break-spaces;
    `

    const commonHooks = useCommon()
    const splitedTitle = sectionTitle ? commonHooks.splistSectionTitle(sectionTitle) : { initial: '', rest: '' }

    return (
        <ItemWrapper>
            <ServiceContentInner>
                {/* Section Title */}
                {sectionTitle && (
                    <ServiceSectionHeading>
                        <SectionHeadingInitial>{splitedTitle.initial}</SectionHeadingInitial>
                        {splitedTitle.rest}
                    </ServiceSectionHeading>
                )}

                {/* Service Title */}
                <ServiceItemHeading>{serviceTitle}</ServiceItemHeading>

                {/* Service Description */}
                <ServiceDescription>{serviceDescription}</ServiceDescription>
            </ServiceContentInner>
        </ItemWrapper>
    )
}

export default ServiceContentComponent
