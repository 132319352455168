import ServiceWrapper from './ServiceWrapperComponent'
import ServiceImg from './ServiceImageComponent'
import ServiceContent from './ServiceContentComponent'
import { useService } from '../../hooks/serviceHooks'

import styled from 'styled-components'

export const ServiceSectionHeading = styled.h2`
    display: block;
    font-family: a-otf-ryumin-pr6n, serif;
    font-size: 40px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 0.5rem;
    margin-bottom: 40px;

    @media screen and (min-width: 1024px) {
        display: none;
    }
`

export const SectionHeadingInitial = styled.span`
    color: #4bad9c;
`

export const ServiceInnerWrapper = styled.div<{ index: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 60px;

    @media screen and (min-width: 1024px) {
        flex-direction: ${(props) => ((props.index + 1) % 2 === 0 ? 'row-reverse' : 'row')};
        gap: 80px;
        height: 100%;
    }
`

/**
 * Serviceセクション
 */
const ServiceComponent = () => {
    const serviceHooks = useService()
    const serviceArray = serviceHooks.serviceArray

    return (
        <div id="service">
            {/* Section Title */}
            <ServiceSectionHeading ref={serviceHooks.serviceHeadingRef}>
                <SectionHeadingInitial>S</SectionHeadingInitial>ervice
            </ServiceSectionHeading>

            {serviceArray.map((service, index) => (
                <ServiceWrapper key={index} index={index} arrayLastIndex={serviceArray.length}>
                    <ServiceInnerWrapper ref={service.serviceRef} index={index}>
                        <ServiceImg imgSrc={service.serviceImg} alt={service.serviceTitle} />
                        <ServiceContent
                            sectionTitle={service.sectionTitle}
                            serviceTitle={service.serviceTitle}
                            serviceDescription={service.serviceDescription}
                            bgIconSrc={service.bgIconSrc}
                            iconRotate={service.iconRotate}
                        />
                    </ServiceInnerWrapper>
                </ServiceWrapper>
            ))}
        </div>
    )
}

export default ServiceComponent
