import styled from 'styled-components'
import logo from '../../assets/logo-color.jpg'

/**
 * ヘッダロゴコンポーネント
 */
const LogoComponent = () => {
    const LogoWrapper = styled.div`
        display: flex;
        align-items: center;
        gap: 10px;
    `

    const LogoImg = styled.img`
        display: block;
        width: 64px;
        height: 64px;
        object-fit: contain;
    `

    const LogoText = styled.p`
        font-size: 32px;
        font-weight: 400;
    `

    return (
        <LogoWrapper>
            <LogoImg src={logo} alt="株式会社STK" />
            <LogoText>株式会社STK</LogoText>
        </LogoWrapper>
    )
}

export default LogoComponent
