import { useRef } from 'react'
import { gsapUtil } from '../common/gsapUtil'
import { useGSAP } from '@gsap/react'

/**
 * Companyセクションのカスタムフック
 */
export const useCompany = () => {
    /**
     * 会社情報
     */
    const companyInfoArray = [
        [
            {
                title: '社名',
                content: ['株式会社STK'],
            },
        ],
        [
            {
                title: '本社所在地',
                content: ['〒305-0031', '茨城県つくば市吾妻1-5-7\nダイワロイネットホテルつくばビル2F'],
            },
            {
                title: '大阪営業所',
                content: ['〒550-0004', '大阪府大阪市西区靱本町3-9-9\n奥内第2ビル2F'],
            },
        ],
        [
            {
                title: '電話番号',
                content: ['029-817-4924'],
            },
        ],
        [
            {
                title: '設立',
                content: ['2024年'],
            },
        ],
        [
            {
                title: '代表取締役',
                content: ['仁藤　高央'],
            },
        ],
    ]

    const gsap = gsapUtil().gsap
    const companyHeadingRef = useRef(null)
    const mapRef = useRef(null)
    const companyInfoRef = useRef(null)

    useGSAP(() => {
        gsap.from(companyHeadingRef.current, {
            opacity: 0,
            duration: 1.5,
            delay: 0.25,
            scrollTrigger: {
                trigger: companyHeadingRef.current,
                start: 'top 80%',
            },
        })
        gsap.from(mapRef.current, {
            opacity: 0,
            duration: 1.5,
            delay: 0.25,
            scrollTrigger: {
                trigger: mapRef.current,
                start: 'top 80%',
            },
        })
        gsap.from(companyInfoRef.current, {
            opacity: 0,
            duration: 1.5,
            delay: 0.25,
            scrollTrigger: {
                trigger: companyInfoRef.current,
                start: 'top 80%',
            },
        })
    })

    return { companyInfoArray, companyHeadingRef, mapRef, companyInfoRef }
}
