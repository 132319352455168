import { useCompany } from '../../hooks/companyHooks'
import Logo from '../company/LogoComponent'
import styled from 'styled-components'

const CompanyInfoComponent = () => {
    const CompanyInfo = styled.div`
        display: flex;
        flex-direction: column;
        width: 80%;
        margin: 0 auto;

        @media screen and (min-width: 768px) {
            width: 100%;
            padding: 20px;
            background-color: #eff5f2;
            border-radius: 20px;
            box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.5);
        }
        @media screen and (min-width: 1024px) {
            flex-direction: row;
            padding: 0;
        }
    `

    const LogoWrapper = styled.div`
        width: 100%;

        @media screen and (min-width: 1024px) {
            width: 40%;
        }
    `
    const LogoInnerWrapper = styled.div`
        display: flex;

        @media screen and (min-width: 1024px) {
            justify-content: center;
            align-items: center;
            height: 100%;
        }
    `

    const ContentWrapper = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        width: 100%;
        padding: 30px 0;

        @media screen and (min-width: 1024px) {
            width: 60%;
        }
    `

    const CompanyInfoRow = styled.div`
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: start;
        }
    `

    const CompanyInfoItem = styled.div`
        width: 100%;

        @media screen and (min-width: 768px) {
            width: 50%;
        }
    `

    const CompanyInfoItemHeading = styled.p`
        font-size: 16px;
        font-weight: 400;
    `
    const CompanyInfoItemContent = styled.p`
        font-size: 16px;
        font-weight: 300;
        white-space: break-spaces;
    `

    const companyHooks = useCompany()
    const companyInfoArray = companyHooks.companyInfoArray

    return (
        <CompanyInfo ref={companyHooks.companyInfoRef}>
            {/* Logo */}
            <LogoWrapper>
                <LogoInnerWrapper>
                    <Logo />
                </LogoInnerWrapper>
            </LogoWrapper>

            {/* Company Info */}
            <ContentWrapper>
                {companyInfoArray.map((companyInfoRow, index) => (
                    <CompanyInfoRow key={index}>
                        {companyInfoRow.map((companyInfoItem, index) => (
                            <CompanyInfoItem key={index}>
                                <CompanyInfoItemHeading>{companyInfoItem.title}</CompanyInfoItemHeading>
                                {companyInfoItem.content.map((content, index) => (
                                    <CompanyInfoItemContent key={index}>{content}</CompanyInfoItemContent>
                                ))}
                            </CompanyInfoItem>
                        ))}
                    </CompanyInfoRow>
                ))}
            </ContentWrapper>
        </CompanyInfo>
    )
}

export default CompanyInfoComponent
